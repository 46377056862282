import React from 'react';
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {Stack, FormControl, FormLabel, Input, Select, Option, FormHelperText} from '@mui/joy';
import {Account, Employee} from 'domain';
import RequiredIndicator from "components/RequiredIndicator";
import SubscriptionNotice from "components/SubscriptionNotice";

const EmployeeForm = ({ onSave, onChange, employee, errors }) => {
    const myAccount = useSelector(({ account }) => account.me);
    const employeeCount = useSelector(({ employees }) => employees.list.length);

    const seatCount = myAccount?.seats ?? 0;
    const billingStatus = myAccount?.billingStatus || Account.BILLING_STATUS_INACTIVE;
    const isDisabled = (
        seatCount <= employeeCount
        || billingStatus === Account.BILLING_STATUS_DEACTIVATED
        ||  billingStatus === Account.BILLING_STATUS_INACTIVE
    )

    if (isDisabled) {
        return <SubscriptionNotice />
    }

    return (
        <Stack component="form" onSubmit={onSave} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <SubscriptionNotice />

            <FormControl error={!!errors.name}>
                <FormLabel>Name<RequiredIndicator /></FormLabel>
                <Input
                    placeholder="Employee Name"
                    size="lg"
                    variant="outlined"
                    value={employee.name}
                    onChange={onChange('name')}
                    error={!!errors.name}
                />
                <FormHelperText>{errors.name}</FormHelperText>
            </FormControl>

            <FormControl error={!!errors.email}>
                <FormLabel>Email<RequiredIndicator /></FormLabel>
                <Input
                    placeholder="Email"
                    size="lg"
                    variant="outlined"
                    value={employee.email}
                    onChange={onChange('email')}
                    error={!!errors.email}
                    disabled={!employee.isNew()}
                />
                <FormHelperText>{errors.email}</FormHelperText>
            </FormControl>

            <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input
                    placeholder="Phone Number"
                    size="lg"
                    variant="outlined"
                    value={employee.phone}
                    onChange={onChange('phone')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Job</FormLabel>
                <Select
                    size="lg"
                    variant="outlined"
                    value={employee.job}
                    onChange={onChange('job')}
                >
                    {Employee.JOBS.map((job) => (
                        <Option key={job} value={job}>{Employee.JOB_LABELS[job]}</Option>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};

EmployeeForm.propTypes = {
    employee: PropTypes.instanceOf(Employee),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

export default EmployeeForm;
